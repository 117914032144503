import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { kebabCase, isString } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CssBaseline from '@material-ui/core/CssBaseline'
import Chip from '@material-ui/core/Chip'
import { Carousel } from 'react-responsive-carousel'
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  useTheme,
  fade,
} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import WarningIcon from '@material-ui/icons/Warning'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import CustomPageContext from '../Context/CustomPageContext'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import AboutSection from '../components/AboutSection'
import appConfig from '../config/config'
import CustomLink from '../components/CustomLink'
import MetaTags from '../components/MetaTags'
import RocketIconSvg from '../img/rocket1.inline.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
  },
  paper: {
    minHeight: '40vh',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rocketSvgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
  },
  rocketSvg: {
    width: '13rem',
    height: '13rem',
    fill: fade(
      theme.palette.getContrastText(theme.palette.background.paper),
      0.8
    ),
  },
  warningIconSvg: {
    position: 'absolute',
    width: '10rem',
    height: '10rem',
    fill: fade('#f00', 0.5),
  },
  textContainer: {
    padding: '0.5rem',
  },
  btnContainer: {
    padding: '0.5rem',
  },
}))

export const NotFoundPageTemplate = ({ helmet, location }) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <section className={classes.root}>
      {helmet || ''}
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          xs={10}
          sm={10}
          md={9}
          lg={7}
          xl={5}
          style={{ width: '100%' }}
        >
          <Paper elevation={1} className={classes.paper}>
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5}
                style={{ width: '100%' }}
                className={classes.rocketSvgContainer}
              >
                <RocketIconSvg className={classes.rocketSvg} />
                <WarningIcon className={classes.warningIconSvg} />
              </Grid>
              <Grid item xs sm md lg xl style={{ width: '100%' }}>
                <div className={classes.textContainer}>
                  <Typography variant="h3" color="textSecondary">
                    404
                  </Typography>
                  <Typography variant="h4">Location Not Found</Typography>
                </div>
                <div className={classes.btnContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: '1rem' }}
                    onClick={() => {
                      window.history.back()
                    }}
                  >
                    Go Back
                  </Button>
                  <CustomLink to="/" style={{ marginRight: '1rem' }}>
                    <Button variant="contained" color="secondary">
                      Go Home
                    </Button>
                  </CustomLink>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </section>
  )
}

NotFoundPageTemplate.propTypes = {
  helmet: PropTypes.node,
  location: PropTypes.object.isRequired,
}

const NotFoundPage = ({ location }) => {
  const initUiTheme =
    typeof window !== 'undefined' ? localStorage.getItem('uiTheme') : null
  const [uiTheme, setUiTheme] = useState(
    initUiTheme || appConfig.defaultInitTheme
  )

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          // type: prefersDarkMode ? 'dark' : 'light',
          type: uiTheme,
          primary: { main: '#212121' },
          secondary: { main: '#DD2C00' },
        },
      }),
    [uiTheme]
  )

  useEffect(() => {
    // Sets the theme config to localstore
    if (uiTheme) {
      localStorage.setItem('uiTheme', uiTheme)
    }
  }, [uiTheme])

  return (
    <CustomPageContext.Provider
      value={{
        uiTheme,
        setUiTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout location={location}>
          <NotFoundPageTemplate
            location={location}
            helmet={<MetaTags pageTitle="404" />}
          />
        </Layout>
      </ThemeProvider>
    </CustomPageContext.Provider>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default NotFoundPage
